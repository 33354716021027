import Home from '../views/Home.vue';
// import testMiddle from "../middlewares/testMiddle";
import AuthMiddleware from '../middlewares/AuthMiddleware';
import VueGateMiddleware from '../middlewares/VueGateMiddleware';

const initialRoutes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            auth: true,
            title: 'RRHH',
            permissions: 'all',
            middlewares: {AuthMiddleware} 
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            auth: false,
            title: 'Catálogos - Áreas',
            permissions: 'all',
            middlewares: {AuthMiddleware} 
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../layouts/Login.vue'),
        meta: {
            auth: false,
            title: 'Iniciar sesión',
            permissions: 'all',
            middlewares: {AuthMiddleware} 
        }
    },

    {
        path: '/update-employee/:token',
        name: 'UpdateByEmployee',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/UpdateByEmployee.vue'),
        meta: {
            auth: false,
            title: 'Empleado',
            permissions: 'all',
            
        }
    },

    {
        path: '/recuperar',
        name: 'RequestPassword',
        component: () => import(/* webpackChunkName: "about" */ '../views/general/RequestPassword.vue'),
        meta: {
            auth: false,
            title: 'Recuperar contraseña',
            permissions: 'all',
            middlewares: {AuthMiddleware},
            layout: "Help"
        }
    },
    {
        path: '/recuperar-enviado',
        name: 'RequestPasswordSend',
        component: () => import(/* webpackChunkName: "about" */ '../views/general/RequestPasswordSend.vue'),
        meta: {
            auth: false,
            title: 'Recuperar contraseña',
            permissions: 'all',
            middlewares: {AuthMiddleware},
            layout: "Message"
        }
    },
    
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "about" */ '../views/general/ResetPassword.vue'),
        meta: {
            auth: false,
            title: 'Restablecer contraseña',
            permissions: 'all',
            middlewares: {AuthMiddleware},
            layout: "Help"
        }
    },
    {
        path: '/restablecida',
        name: 'ResetPasswordSuccess',
        component: () => import(/* webpackChunkName: "about" */ '../views/general/ResetPasswordSuccess.vue'),
        meta: {
            auth: false,
            title: 'Contraseña restablecida',
            permissions: 'all',
            middlewares: {AuthMiddleware},
            layout: "Message"
        }
    },
    {
        path: '/email/verify/:token',
        name: 'ConfirmedAccount',
        component: () => import(/* webpackChunkName: "about" */ '../views/general/AccountConfirmation.vue'),
        meta: {
            auth: false,
            title: 'Contraseña restablecida',
            permissions: 'all',
            middlewares: {AuthMiddleware},
            layout: "Message"
        }
    },
]


/**
 *  !CATALOGS 
*/
const catalogRoutes = [
//   //Areas
//   {
//       path: '/areas',
//       name: 'Areas',
//       component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/areas/Index.vue'),
//       meta: {
//         parent:'Catalogs',
//         auth: true,
//         title: 'Catálogos - Áreas',
//         middlewares: {AuthMiddleware,VueGateMiddleware},
//         permissions: 'areas.show'
//       }
//   },
//   {
//     path: '/areas/nuevo',
//     name: 'AreasCreate',
//     component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/areas/Create.vue'),
//     meta: {
//       parent:'Catalogs',
//       auth: true,
//       title: 'Catálogos - Áreas',
//       permissions: 'areas.create',
//       middlewares: {AuthMiddleware,VueGateMiddleware} 
//     }
//   },
//   {
//     path: '/areas/:id/edicion',
//     name: 'AreasEdit',
//     component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/areas/Edit.vue'),
//     meta: {
//       parent:'Catalogs',
//       auth: true,
//       title: 'Catálogos - Áreas',
//       permissions: 'areas.update',
//       middlewares: {AuthMiddleware,VueGateMiddleware} 
//     }
//   },
//   {
//     path: '/areas/:id/detalle',
//     name: 'AreasDetails',
//     component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/areas/Details.vue'),
//     meta: {
//       parent:'Catalogs',
//       auth: true,
//       title: 'Catálogos - Áreas',
//       permissions: 'areas.show',
//       middlewares: {AuthMiddleware,VueGateMiddleware} 
//     }
//   },

  //positions
  {
    path: '/puestos',
    name: 'Positions',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/positions/index.vue'),
    meta: {
      parent:'Catalogs',
      auth: true,
      title: 'Catálogos - Puestos',
      permissions: 'positions.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/puestos/nuevo',
    name: 'PositionsCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/positions/Create.vue'),
    meta: {
      parent:'Catalogs',
      auth: true,
      title: 'Catálogos - Puestos',
      permissions: 'positions.create',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/puestos/:id/edicion',
    name: 'PositionsEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/positions/Edit.vue'),
    meta: {
      parent:'Catalogs',
      auth: true,
      title: 'Catálogos - Puestos',
      permissions: 'positions.update',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/puestos/:id/detalle',
    name: 'PositionsDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/positions/Details.vue'),
    meta: {
      parent:'Catalogs',
      auth: true,
      title: 'Catálogos - Puestos',
      permissions: 'positions.show',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },


  //locations
  {
    path: '/locaciones',
    name: 'Locations',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/locations/Index.vue'),
    meta: {
      parent:'Catalogs',
      auth: true,
      title: 'Catálogos - Locaciones',
      permissions: 'branch_offices.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/locaciones/nuevo',
    name: 'LocationsCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/locations/Create.vue'),
    meta: {
      parent:'Catalogs',
      auth: true,
      title: 'Catálogos - Locaciones',
      permissions: 'branch_offices.create',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/locaciones/:id/edicion',
    name: 'LocationsEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/locations/Edit.vue'),
    meta: {
      parent:'Catalogs',
      auth: true,
      title: 'Catálogos - Locaciones',
      permissions: 'branch_offices.update',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/locaciones/:id/detalle',
    name: 'LocationsDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/locations/Details.vue'),
    meta: {
      parent:'Catalogs',
      auth: true,
      title: 'Catálogos - Locaciones',
      permissions: 'branch_offices.show',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },

    //departments
    {
        path: '/departamentos',
        name: 'Departments',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/departments/Index.vue'),
        meta: {
        parent:'Catalogs',
        auth: true,
        title: 'Catálogos - Departamentos',
        permissions: 'departments.show',
        middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },
    {
        path: '/departamentos/nuevo',
        name: 'DepartmentsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/departments/Create.vue'),
        meta: {
        parent:'Catalogs',
        auth: true,
        title: 'Catálogos - Departamentos',
        permissions: 'departments.create',
        middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },
    {
        path: '/departamentos/:id/edicion',
        name: 'DepartmentsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/departments/Edit.vue'),
        meta: {
        parent:'Catalogs',
        auth: true,
        title: 'Catálogos - Departamentos',
        permissions: 'departments.update',
        middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },

    //holidays
    {
        path: '/dias-festivos',
        name: 'Holidays',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/catalogs/holidays/Index.vue'),
        meta: {
        parent:'Catalogs',
        auth: true,
        title: 'Catálogos - Días festivos',
        permissions: 'holidays.show',
        middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },

];

/**
 * !Inventory
 */
const inventoryRoutes = [
  {
    path: '/patrimonio',
    name: 'Inventory',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/inventory/Index.vue'),
    meta: {
      auth: true,
      title: 'Patrimonio',
      permissions: 'business_assets.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/patrimonio/nuevo',
    name: 'InventoryCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/inventory/Create.vue'),
    meta: {
      auth: true,
      title: 'Patrimonio',
      permissions: 'business_assets.create',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/patrimonio/:id/asignar',
    name: 'InventoryAssign',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/inventory/Assign.vue'),
    meta: {
      auth: true,
      title: 'Patrimonio',
      permissions: 'business_assets.create',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/patrimonio/:id/edicion',
    name: 'InventoryEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/inventory/Edit.vue'),
    meta: {
      auth: true,
      title: 'Patrimonio - Editar activo',
      permissions: 'business_assets.update',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/patrimonio/:id/detalles',
    name: 'InventoryDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/inventory/Details.vue'),
    meta: {
      auth: true,
      title: 'Patrimonio - Activos',
      permissions: 'business_assets.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/patrimonio/:id/mantenimiento',
    name: 'InventoryCreateMaintenance',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/inventory/CreateMaintenance.vue'),
    meta: {
      auth: true,
      title: 'Patrimonio - Registrar mantenimiento',
      permissions: 'maintenances.create',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
];

const payrollRoutes = [

    //*index payroll
    {
      path: '/nomina',
      name: 'Payroll',
      component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/payroll/Index.vue'),
      meta: {
        parent:'Payrolls',
        auth: true,
        title: 'Nóminas - Nómina',
        permissions: 'payroll_payments.show',
        middlewares: {AuthMiddleware,VueGateMiddleware}
      }
    },


    //*Employees payroll payslip
    {
        path: '/empleados-nomina',
        name: 'PayrollEmployees',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/payroll/employees/Index.vue'),
        meta: {
          parent:'Payrolls',
          auth: true,
          title: 'Nóminas - Empleados',
          permissions: 'payslip.show',
          middlewares: {AuthMiddleware,VueGateMiddleware}
        }
    },
    {
        path: '/empleados-nomina/:id/editar',
        name: 'PayrollEmployeesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/payroll/employees/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nóminas - Editar nómina',
            permissions: 'payslip.update',
            middlewares: {AuthMiddleware, VueGateMiddleware},
        }
    },
    {
        path: '/empleados-nomina/:id/detalles',
        name: 'PayrollEmployeesDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/payroll/employees/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nóminas - Detalles nómina',
            permissions: 'payslip.show',
            middlewares: {AuthMiddleware, VueGateMiddleware},
        }
    },

    /**
     * ! Annual budget
     */
    {
        path: '/presupuesto-anual',
        name: 'AnnualBudget',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/payroll/annualBudget/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nóminas - Presupuesto anual',
            permissions: 'payslip_reports.show',
            middlewares: {AuthMiddleware, VueGateMiddleware},
        }
    },

];

/**
 * !RRHH
 */
const RRHHRoutes = [

  //*Employees
  {
    path: '/empleados-area',
    name: 'EmployeesArea',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/IndexArea.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados',
      permissions: 'area_rol_user.show',
      unlessPermission: 'employee_site.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/empleados',
    name: 'Employees',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/Index.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados',
      permissions: 'employees.show',
      unlessPermission: 'employee_site.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/empleados/nuevo',
    name: 'EmployeeCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/Create.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados - Nuevo empleado',
      permissions: 'employees.create',
      unlessPermission: 'area_rol_user.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/empleados/eventualidad/:id/nuevo',
    name: 'EmployeeEventCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/EventCreate.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados - Nueva eventualidad',
      unlessPermission: 'area_rol_user.show',
      permissions: 'employees.create',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/empleados/:id/estadisticas',
    name: 'EmployeeStatistic',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/Statistic.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados - Estadísticas',
      permissions: 'employees.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/empleados/:employee/estadisticas/:eventId',
    name: 'EmployeeEventEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/EventEdit.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados - Editar eventualidad',
      permissions: 'employees.update',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/empleados/:id/edicion',
    name: 'EmployeeEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/Edit.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados - Editar empleado',
      permissions: 'employees.update',
      unlessPermission: 'area_rol_user.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/empleados/:id/vacaciones/nuevo',
    name: 'EmployeeVacationsCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/CreateVacation.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados - Nuevas vacaciones',
      permissions: 'vacations.create',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/empleados/:id/vacaciones/:vacation_id',
    name: 'EmployeeVacationsEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/EditVacation.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados - Editar vacaciones',
      permissions: 'vacations.update',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/empleados/baja',
    name: 'EmployeesLayoff',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/LayoffCreate.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados - Baja',
      permissions: 'employees.delete',
      unlessPermission: 'area_rol_user.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/survey-lo-merca/:id',
    name: 'surveyLayoff',
    // component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/SurveyLayoff.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados',
      permissions: 'employees.delete',
      unlessPermission: 'employee_site.show',
      middlewares: {AuthMiddleware,VueGateMiddleware},
      layout: "SurveyLayoff"
    }
  },
  {
    path: '/survey-thanks',
    name: 'surveyLayoffThanks',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/employees/SurveyThanks.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Empleados',
      permissions: 'employees.delete',
      unlessPermission: 'employee_site.show',
      middlewares: {AuthMiddleware,VueGateMiddleware},
      layout: "Message"
    }
  },


  //*assistance
  {
    path: '/asistencias',
    name: 'Attendances',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/attendances/Index.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Asistencias',
      permissions: 'attendances.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },


  //*jobs
  {
    path: '/vacantes',
    name: 'Jobs',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/jobs/Index.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Vacantes',
      permissions: 'vacancies.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/vacantes/nuevo',
    name: 'JobsCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/jobs/Create.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Vacantes',
      permissions: 'vacancies.create',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/vacantes/:id',
    name: 'JobsEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/jobs/Edit.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Vacantes - Editar vacante',
      permissions: 'vacancies.update',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/vacantes/:jobId/candidatos',
    name: 'Candidates',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/jobs/candidates/Index.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Vacantes - Candidatos',
      permissions: 'candidates.show',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/vacantes/:jobId/candidatos/nuevo',
    name: 'CandidatesCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/jobs/candidates/Create.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Vacantes - Candidatos',
      permissions: 'candidates.create',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/vacantes/:jobId/candidatos/:candidateId/edicion',
    name: 'CandidatesEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/jobs/candidates/Edit.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Vacantes - Candidatos',
      permissions: 'candidates.update',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/vacantes/:jobId/candidatos/:candidateId/process',
    name: 'CandidatesProcess',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/jobs/candidates/Process.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Vacantes - Candidatos',
      permissions: 'candidates.show',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },


  //* questionnaires
  {
    path: '/cuestionarios',
    name: 'Questionary',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/questionnaires/Index.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Cuestionarios',
      permissions: 'surveys.show',
      middlewares: {AuthMiddleware,VueGateMiddleware}
    }
  },
  {
    path: '/cuestionarios/nuevo',
    name: 'QuestionaryCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/questionnaires/Create.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Cuestionarios - Nuevo cuestionario',
      permissions: 'surveys.create',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
  {
    path: '/cuestionarios/:id/edicion',
    name: 'QuestionaryEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/rrhh/questionnaires/Edit.vue'),
    meta: {
      parent:'RRHH',
      auth: true,
      title: 'Cuestionarios - Editar cuestionario',
      permissions: 'surveys.update',
      middlewares: {AuthMiddleware,VueGateMiddleware} 
    }
  },
];


/**
 *  !ACCESS 
*/
const accessRoutes = [
    //Users
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/access/users/Index.vue'),
        meta: {
          parent:'Access',
          auth: true,
          title: 'Accesos - Usuarios',
          permissions: 'users.show',
          middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },
    {
        path: '/users/new',
        name: 'UsersCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/access/users/Create.vue'),
        meta: {
          parent:'Access',
          auth: true,
          title: 'Accesos - Nuevo Usuario',
          permissions: 'users.create',
          middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },
    {
        path: '/users/:id/edit',
        name: 'UsersEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/access/users/Edit.vue'),
        meta: {
          parent:'Access',
          auth: true,
          title: 'Accesos - Editar Usuario',
          permissions: 'users.update',
          middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },

    /**
     * ! ROLE
    */
    //roles
    {
        path: '/roles',
        name: 'Roles',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/access/roles/Index.vue'),
        meta: {
          parent:'Access',
          auth: true,
          title: 'Accesos - Roles',
          permissions: 'roles.show',
          middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },
    {
        path: '/roles/new',
        name: 'RolesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/access/roles/Create.vue'),
        meta: {
          parent:'Access',
          auth: true,
          title: 'Accesos - Nuevo Rol',
          permissions: 'roles.create',
          middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'RolesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/access/roles/Edit.vue'),
        meta: {
          parent:'Access',
          auth: true,
          title: 'Accesos - Editar Rol',
          permissions: 'roles.update',
          middlewares: {AuthMiddleware,VueGateMiddleware} 
        }
    },
];

/**
 *  !EVENTS 
*/
const eventsRoutes = [
    //events
    {
        path: '/events',
        name: 'Events',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/events/Index.vue'),
        meta: {
            auth: true,
            title: 'Eventos',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'events.show',
        }
    },
    {
        path: '/events/new',
        name: 'EventsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/events/Create.vue'),
        meta: {
            auth: true,
            title: 'Eventos - Nuevo evento',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'events.create',
        }
    },
    {
        path: '/events/:id/edit',
        name: 'EventsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/events/Edit.vue'),
        meta: {
            auth: true,
            title: 'Eventos - Editar evento',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'events.update',
        }
    },
    {
        path: '/events/:id/details',
        name: 'EventsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/events/Details.vue'),
        meta: {
            auth: true,
            title: 'Eventos - Detalles del evento',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'events.show',
        }
    },
];

/**
 *  !EVENTS 
*/
const announcementRoutes = [
    //announcements
    {
        path: '/announcements',
        name: 'Announcements',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/announcements/Index.vue'),
        meta: {
            auth: true,
            title: 'Avisos',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'announcements.show',
        }
    },
    {
        path: '/announcements/new',
        name: 'AnnouncementsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/announcements/Create.vue'),
        meta: {
            auth: true,
            title: 'Avisos - Nuevo aviso',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'announcements.create',
        }
    },
    {
        path: '/announcements/:id/edit',
        name: 'AnnouncementsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/announcements/Edit.vue'),
        meta: {
            auth: true,
            title: 'Avisos - Editar aviso',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'announcements.update',
        }
    },
    {
        path: '/announcements/:id/details',
        name: 'AnnouncementsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/announcements/Details.vue'),
        meta: {
            auth: true,
            title: 'Avisos - Detalles del aviso',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'announcements.show',
        }
    },
];

/**
 *  !CALENDAR 
*/
const calendarRoutes = [
    //announcements
    {
        path: '/calendar',
        name: 'Calendar',
        component: () => import(/* webpackChunkName: "about" */ '../views/managerViews/calendar/Index.vue'),
        meta: {
            auth: true,
            title: 'Avisos',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'calendar.show',
        }
    },
];



/**
 *  !EMPLOYEE VIEWS 
*/

/**
 *  !My info 
*/
const employeeResetPasswordRoutes = [
  //announcements
  {
      path: '/m/reset-password',
      name: 'ResetPasswordEmployee',
      component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/reset-password/Index.vue'),
      meta: {
          auth: true,
          title: 'Nueva contraseña',
          middlewares: {AuthMiddleware,VueGateMiddleware},
          permissions: 'employee_site.show',
      }
  },
];


/**
 *  !My info 
*/
const employeeMyInfoRoutes = [
    //my info employee
    {
        path: '/m/my-info',
        name: 'MyInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/myInfo/Index.vue'),
        meta: {
            auth: true,
            title: 'Mis Datos',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
    {
        path: '/m/my-info/changePassword',
        name: 'MyInfoChangePassword',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/myInfo/ResetPasswordProfile.vue'),
        meta: {
            auth: true,
            title: 'Cambiar contraseña',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
    {
        path: '/m/my-info/position',
        name: 'PositionInformation',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/myInfo/PositionInfo.vue'),
        meta: {
            auth: true,
            title: 'Descripción de puesto',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
];

/**
 *  !VACATIONS 
*/
const employeeVacationRoutes = [
    //vacations
    {
        path: '/m/vacations',
        name: 'EmployeeVacations',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/vacations/Index.vue'),
        meta: {
            auth: true,
            title: 'Vacaciones',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
    {
        path: '/m/vacations/request',
        name: 'EmployeeVacationsRequest',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/vacations/Request.vue'),
        meta: {
            auth: true,
            title: 'Vacaciones',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
    {
        path: '/m/vacations/:id/confirmation',
        name: 'EmployeeVacationsConfirm',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/vacations/Confirm.vue'),
        meta: {
            auth: true,
            title: 'Vacaciones',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
    {
        path: '/m/vacations/:id/details',
        name: 'EmployeeVacationsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/vacations/Details.vue'),
        meta: {
            auth: true,
            title: 'Vacaciones',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
];
/**
 *  !ATTENDANCE 
*/
const employeeAttendanceRoutes = [
    //announcements
    {
        path: '/m/attendance',
        name: 'Atendance',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/attendance/Index.vue'),
        meta: {
            auth: true,
            title: 'Asistencias',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
];
/**
 *  !EQUIPMENT 
*/
const employeeEquipmentRoutes = [
    //announcements
    {
        path: '/m/equipment',
        name: 'Equipment',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/equipment/Index.vue'),
        meta: {
            auth: true,
            title: 'Equipo',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
];

/**
 *  !PAYROLL 
*/
const employeePayrollRoutes = [
    //payroll
    {
        path: '/m/payroll',
        name: 'EmployeePayroll',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/payroll/Index.vue'),
        meta: {
            auth: true,
            title: 'Mis recibos',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
];

/**
 *  !EVENTS 
*/
const employeeEventsRoutes = [
    {
        path: '/m/events',
        name: 'EmployeeEvents',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/events/Index.vue'),
        meta: {
            auth: true,
            title: 'Eventos',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
    {
        path: '/m/events/:id/details',
        name: 'EmployeeEventsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/employeeViews/events/Details.vue'),
        meta: {
            auth: true,
            title: 'Detalles de evento',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'employee_site.show',
        }
    },
];

export const routes = [
    ...initialRoutes,
    ...catalogRoutes,
    ...inventoryRoutes,
    ...RRHHRoutes,
    ...accessRoutes,
    ...eventsRoutes,
    ...announcementRoutes,
    ...calendarRoutes,
    ...payrollRoutes,


    //Employee view routes
    ...employeeMyInfoRoutes,
    ...employeeVacationRoutes,
    ...employeeAttendanceRoutes,
    ...employeeEquipmentRoutes,
    ...employeeEventsRoutes,
    ...employeeResetPasswordRoutes,
    ...employeePayrollRoutes
];