<template>
    <!-- <v-container class="pb-0 pt-0" > -->
        <v-bottom-navigation
        :model="value"
        
        :input-value="active"
        app
        background-color="sidebarPrimary"
        color="sidebarTextActive"
        dark
        
    >
        <v-btn :disabled="disabled" v-for="(modulo, i) in menu" :key="i" v-permission:any="modulo.permissions" :to="modulo.link" class="button-bar-item"  active-class="button-bar-item-active">
            <span>{{modulo.title}}</span>

            <v-icon class="mb-1">{{modulo.icon}}</v-icon>
        </v-btn>


       
    </v-bottom-navigation>
    <!-- </v-container> -->
</template>

<script>
import VueGatesPlugin from '../plugins/vue-gates';
	export default {
		name: 'ButtonBar',
		watch: {
			$route(to) {
				if (to.matched.length >= 1) {
					const parent = to.matched.filter(m => !m.parent)[0];
					const route = this.menu.filter(route => route.name === parent.meta.parent)[0];
					if(route != undefined)
						route.active = true;
                    
				}
               
			}
		},
       computed: {
            disabled: {
                get: function() {
                    return this.$store.state.userData.has_temp_password;
                   
                }
               
            },
            
        
        },
		data () {
			return {
                value: 1,
                active: true,
                // disabled:false,
				menu: [
					{
						title: 'Mi Perfil',
						name: 'MyData',
						icon: 'mdi-account-outline',
						active: false,
						submenu: [],
                        permissions: 'employee_site.show',
						link: '/m/my-info'
					},
                     {
						title: 'VACACIONES',
						name: 'Vacations',
						icon: 'mdi-umbrella-beach-outline',
						active: false,
						submenu: [],
                        permissions: 'employee_site.show',
						link: '/m/vacations'
					},
                    {
						title: 'EQUIPO',
						name: 'Equipment',
						icon: 'mdi-cellphone-link',
						active: false,
						submenu: [],
                        permissions: 'employee_site.show',
						link: '/m/equipment'
					},
                    {
						title: 'Recibos',
						name: 'EmployeePayroll',
						icon: 'mdi-file-document-outline',
						active: false,
						submenu: [],
                        permissions: 'employee_site.show',
						link: '/m/payroll'
					},
                    {
						title: 'Asistencias',
						name: 'Assistance',
						icon: 'mdi-hand-front-left-outline',
						active: false,
						submenu: [],
                        permissions: 'employee_site.show',
						link: '/m/attendance'
					},
                   
                    {
						title: 'Eventos',
						name: 'Events',
						icon: 'mdi-account-group-outline',
						active: false,
						submenu: [],
                        permissions: 'employee_site.show',
						link: '/m/events'
					},
					
                    
					
				],
				right: null,
			}
		},
        mounted(){
            VueGatesPlugin.init();
        },
		methods: {
			
		}
	};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
    .theme--light.v-bottom-navigation .v-btn:not(.v-btn--active){
        a{
            color: red !important;
        }
    }
    .button-bar{
        &-item{
            min-width: 60px !important;
            a {
               
                color: var(--v-sidebarText-base) !important;
            }
            span{

                font-size: .5rem !important;
            }
        }
        &-item-active{
            a{

                color: var(--v-primary-base) !important;
            }
            background-color: var(--v-primary-base) !important;
            span{

                font-size: .5rem !important;
            }
            // background-color: ;
        }
    }

//   .footer{
//     &-container{
//     clear: both;
//     position: relative;
//     height: 200px;
//     margin-top: 100px;
//     }

//     &-text{
//       font-size: small;
//       color: gray;
//       margin-top: 2px;
//       @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
//         text-align: center;
//       }
//     }
//     &-icon{
//       margin-top: 2px !important;
//     }
//   }
</style>