
import BaseApi from "../BaseApi";

export default class EmployeeEmployeeService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * my info
     * Get employee's information
     */
    async myInfo()
    {
        return this.http.get(`${this.baseUrl}/app/employees/me/detail`)
        .catch(this.handleErrorResponse);
    }

    /**
     * Change password
     */
    async updatePassword(data)
    {
        return this.http.put(`${this.baseUrl}/app/employees/me/update-password`, data)
        .catch(this.handleErrorResponse);
    } 


    async getPaychecks(year)
    {
        return this.http.get(`${this.baseUrl}/app/employees/paychecks/year/${year}`)
        .catch(this.handleErrorResponse);
    }

    async getPosition()
    {
        return this.http.get(`${this.baseUrl}/app/employees/me/position`)
        .catch(this.handleErrorResponse);
    }
}